import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useLanguage } from "../context/LanguageContext";

const NotFound = () => {
    const {translations} = useLanguage();
    return (
        <section className="flex justify-center items-center min-h-screen cursor-default">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center">
                    <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-white">404</h1>
                    <p className="mb-4 text-3xl tracking-tight font-bold text-white md:text-4xl">
                        {translations.PAGE_NOT_FOUND}
                    </p>
                    <p className="mb-4 text-lg font-base text-gray-100">
                        {translations.PAGE_NOT_FOUND_DESCRIPTION}
                    </p>
                    <a href="/"
                       className="inline-flex text-white bg-red-800 hover:bg-white hover:text-black transition duration-300
                        focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4">
                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2 py-1"/>
                        {translations.GO_BACK}
                    </a>
                </div>
            </div>
        </section>
    );
}

export default NotFound;
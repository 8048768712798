type Translations = {
    [key: string]: string;
};

export const EN_TRANSLATIONS: Translations = {
    HOME: "Home",
    RINGTONE: "Ringtone",
    VIDEO: "Video",
    COLLABORATION: "Collaboration",
    FAQ: "FAQ",
    EMAIL_LABEL: "Email",
    YOUTUBE: "YouTube",
    INSTAGRAM: "Instagram",
    FACEBOOK: "Facebook",
    TIKTOK: "TikTok",
    TWITTER: "Twitter",
    SWITCH_LANGUAGE: "Switch to Turkish",
    RINGTONE_DOWNLOAD: "Ringtone Download",
    DESCRIPTION: "Experience the hypnotic rhythm of mesmerizing music videos with creative animations!!!",
    DEVELOPER: "Designed and Developed by",
    RIGHTS: "All Rights Reserved.",
    ABOUT_US: "About Us",
    ARE_YOU_SURE: "Are you sure you want to download this file?",
    CONFIRM_DOWNLOAD: "Yes, download it!",
    CANCEL: "No, cancel!",
    MP3_DOWNLOAD: "MP3 Download",
    PAGE_NOT_FOUND: "Something's missing.",
    PAGE_NOT_FOUND_DESCRIPTION: "Sorry, we can't find that page. You can go back to the homepage.",
    GO_BACK: "Back to Homepage",
};

export const TR_TRANSLATIONS: Translations = {
    HOME: "Anasayfa",
    RINGTONE: "Zil Sesi",
    VIDEO: "Video",
    COLLABORATION: "İşbirlikleri",
    FAQ: "SSS",
    EMAIL_LABEL: "E-posta",
    YOUTUBE: "YouTube",
    INSTAGRAM: "Instagram",
    FACEBOOK: "Facebook",
    TIKTOK: "TikTok",
    TWITTER: "Twitter",
    SWITCH_LANGUAGE: "İngilizceye Geç",
    RINGTONE_DOWNLOAD: "Zil Sesi İndir",
    DESCRIPTION: "Yaratıcı animasyonlarla büyüleyici müzik videolarının hipnotik ritmini deneyimleyin!!!",
    DEVELOPER: "Tasarlayan ve Geliştiren",
    RIGHTS: "Tüm Hakları Saklıdır.",
    ABOUT_US: "Hakkımızda",
    ARE_YOU_SURE: "Bu dosyayı indirmek istediğinize emin misiniz?",
    CONFIRM_DOWNLOAD: "Evet, indir!",
    CANCEL: "Hayır, iptal!",
    MP3_DOWNLOAD: "MP3 İndir",
    PAGE_NOT_FOUND: "Bir şeyler ters gitti.",
    PAGE_NOT_FOUND_DESCRIPTION: "Üzgünüz, bu sayfayı bulamıyoruz. Ana sayfaya geri dönebilirsiniz.",
    GO_BACK: "Ana Sayfaya Dön",
};
import ReactCountryFlag from "react-country-flag";
import React from "react";

const PDFButton = (
    {
        countryCode,
        label,
        fileName,
        filePath,
        onDownload,
    }: {
        countryCode: string;
        label: string;
        fileName: string;
        filePath: string;
        onDownload: (fileName: string, filePath: string) => void;
    }) => (
    <button
        onClick={() => onDownload(fileName, filePath)}
        className="w-full text-center px-1 py-2 text-sm text-white rounded-md hover:bg-gray-100 hover:text-gray-900 flex items-center justify-center"
    >
        <ReactCountryFlag
            countryCode={countryCode}
            svg
            style={{width: '1.5em', height: '1.5em'}}
            aria-label={`${label} flag`}
        />
        <span className="ml-2">{label}</span>
    </button>
);

export default PDFButton;
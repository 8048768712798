import React from "react";
import MusicPlayer from "../components/MusicPlayer";
import { useLanguage } from "../context/LanguageContext";

const Ringtone = () => {
    const {translations} = useLanguage();

    return (
        <div className={"flex justify-center mt-28 "}>
            <a className={"anchor"} id={"RINGTONE"}/>
            <div className={"w-1/3"}>
                <h1 className="flex flex-row justify-center items-center text-lg md:text-xl font-bold text-white text-center mb-4 whitespace-nowrap">
                    {translations.RINGTONE_DOWNLOAD}
                </h1>
                <MusicPlayer/>
            </div>
        </div>
    );
}

export default Ringtone;
import React from 'react';

const Logo = () => (
    <a href={"/"}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#fff"
            className="bi bi-google logo"
            viewBox="0 0 1840 1059.62"
        >
            <path
                d="M1829.19,1.48L147.83,837.65c-4.97,2.47-10.81-1.14-10.81-6.7V230.22c0-5.58,5.88-9.19,10.86-6.68l333.43,168.44c5.5,2.78,5.47,10.64-.04,13.38l-118.91,59.13c-2.54,1.26-4.15,3.86-4.15,6.7v136.36c0,5.56,5.84,9.17,10.81,6.7l548.78-272.94c2.54-1.26,4.15-3.86,4.15-6.7v-136.32c0-5.55-5.84-9.17-10.81-6.7l-260.01,129.3c-2.11,1.05-4.6,1.04-6.71-.02L10.86.81C5.88-1.7,0,1.92,0,7.49v1044.63c0,5.55,5.84,9.17,10.81,6.7L1692.17,222.66c4.97-2.47,10.81,1.14,10.81,6.7v602.74c0,5.53-5.79,9.15-10.76,6.73l-327.82-159.59c-5.58-2.72-5.62-10.66-.06-13.43l117.21-58.29c2.54-1.26,4.15-3.86,4.15-6.7v-136.32c0-5.55-5.84-9.17-10.81-6.7l-548.78,272.9c-2.54,1.26-4.15,3.86-4.15,6.7v136.36c0,5.56,5.84,9.17,10.81,6.7l259.82-129.22c2.08-1.03,4.52-1.04,6.61-.03l630.05,306.72c4.97,2.42,10.76-1.2,10.76-6.73V8.18c0-5.55-5.84-9.17-10.81-6.7Z"
            />
        </svg>
    </a>
);

export default Logo;
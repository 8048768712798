import Swal from "sweetalert2";

export class DownloadHelper {
    static triggerDownload(fileName: string, filePath: string): void {
        const link = document.createElement('a');
        link.href = filePath;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    static showDownloadConfirmation(
        title: string,
        translations: Record<string, string>,
        onConfirm: () => void
    ): void {
        Swal.fire({
            title: title,
            text: translations.ARE_YOU_SURE,
            icon: 'warning',
            showCancelButton: true,
            background: '#1a202c',
            color: 'white',
            confirmButtonColor: '#227a0b',
            cancelButtonColor: '#ca2121ff',
            confirmButtonText: translations.CONFIRM_DOWNLOAD,
            cancelButtonText: translations.CANCEL,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) onConfirm();
        });
    }
}

import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import PDFButton from "../buttons/PDFButton";
import { useLanguage } from "../../context/LanguageContext";
import { DownloadHelper } from "../../utils/DownloadHelper";
import { Urls } from "../../constants/Url";

const PDFDropdown = () => {
    const {translations} = useLanguage();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleDownload = (fileName: string, filePath: string) => {
        DownloadHelper.showDownloadConfirmation(translations.ABOUT_US, translations, () => DownloadHelper.triggerDownload(fileName, filePath));
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const path = event.composedPath && event.composedPath();
            if (dropdownRef.current && !path.includes(dropdownRef.current)) setDropdownOpen(false);
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <div ref={dropdownRef} className="relative inline-block text-left">
            <div>
                <button
                    type="button"
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    className="mt-4 inline-flex justify-center w-full rounded-md shadow-sm px-6 py-2 bg-red-800 text-sm font-medium text-white hover:bg-gray-50 focus:outline-none"
                >
                    {translations.ABOUT_US} <FontAwesomeIcon className="ml-2 text-lg" icon={faFilePdf}/>
                    <FontAwesomeIcon icon={dropdownOpen ? faChevronUp : faChevronDown} className="ml-2"/>
                </button>
            </div>
            {dropdownOpen && (
                <div
                    className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-red-800 ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                >
                    <PDFButton
                        countryCode="TR"
                        label="Tricktri-TR"
                        fileName="Tricktri-TR.pdf"
                        filePath={`${Urls.BASE}/about/Tricktri-TR.pdf`}
                        onDownload={handleDownload}
                    />
                    <PDFButton
                        countryCode="US"
                        label="Tricktri-EN"
                        fileName="Tricktri-EN.pdf"
                        filePath={`${Urls.BASE}/about/Tricktri-EN.pdf`}
                        onDownload={handleDownload}
                    />
                </div>
            )}
        </div>
    );
};

export default PDFDropdown;
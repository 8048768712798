import React, { createContext, ReactNode, useContext, useState } from "react";
import { EN_TRANSLATIONS, TR_TRANSLATIONS } from "../constants/Translations";

const TR = 'TR';
const EN = 'EN';
type Language = 'EN' | 'TR';

interface LanguageContextProps {
    language: Language;
    translations: { [key: string]: string };
    toggleLanguage: () => void;
}

const LanguageContext = createContext<LanguageContextProps | undefined>(undefined);

export const LanguageProvider = ({children}: { children: ReactNode }) => {
    const getInitialLanguage = (): Language => {
        const browserLanguage = navigator.language;
        if (browserLanguage.startsWith('tr')) return TR;
        else return EN;
    };

    const [language, setLanguage] = useState<Language>(getInitialLanguage);
    const toggleLanguage = () => setLanguage(prevLanguage => (prevLanguage === EN ? TR : EN));
    const translations = language === EN ? EN_TRANSLATIONS : TR_TRANSLATIONS;

    return (
        <LanguageContext.Provider value={{language, translations, toggleLanguage}}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = (): LanguageContextProps => {
    const context = useContext(LanguageContext);
    if (!context) throw new Error("useLanguage bir LanguageProvider içinde kullanılmalıdır.");

    return context;
};

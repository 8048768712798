import React, { useEffect, useRef, useState } from "react";
import AudioPlayer from 'react-h5-audio-player';
import Marquee from "react-fast-marquee";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import '../styles/musicplayer.css';
import { DownloadHelper } from "../utils/DownloadHelper";
import { useLanguage } from "../context/LanguageContext";
import { Urls } from "../constants/Url";

type Track = {
    src: string;
    name: string;
    author: string;
};

const playlistUrl = `${Urls.BASE}/json/playlist.json`;
const renderActiveTrackMarquee = (track: Track) => (
    <Marquee speed={75} pauseOnHover={true}>{track.name}&nbsp;</Marquee>
);

const renderTrackLabel = (track: Track) => `${track.name}`;
const isAppleDevice = () => /iPad|iPhone|iPod/.test(navigator.userAgent);
const getCustomVolumeControls = () => isAppleDevice() ? [] : undefined;

const MusicPlayer = () => {
    const {translations} = useLanguage();
    const [playlist, setPlaylist] = useState<Track[]>([]);
    const [currentTrack, setTrackIndex] = useState(0);
    const [isPlayerFocused, setPlayerFocused] = useState(false);
    const trackRefs = useRef<(HTMLLIElement | null)[]>([]);

    useEffect(() => {
        fetch(playlistUrl)
            .then(response => response.json())
            .then(data => setPlaylist(data))
            .catch(error => console.error('Error fetching playlist:', error));
    }, []);

    useEffect(() => {
        if (currentTrack >= playlist.length) setTrackIndex(0);

        if (isPlayerFocused) {
            trackRefs.current[currentTrack]?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [currentTrack, playlist, isPlayerFocused]);

    const changeTrack = (newIndex: number) => {
        setPlayerFocused(true);
        setTrackIndex(newIndex);
    };

    const handleNextTrack = () => changeTrack((currentTrack + 1) % playlist.length);
    const handlePreviousTrack = () => changeTrack((currentTrack - 1 + playlist.length) % playlist.length);

    const handleDownload = (fileName: string, filePath: string) => {
        DownloadHelper.showDownloadConfirmation(translations.MP3_DOWNLOAD, translations, () => DownloadHelper.triggerDownload(fileName, filePath));
    };

    if (playlist.length === 0) {
        return <div className={"flex flex-col items-center justify-center font-light text-white"}>Loading...</div>;
    }

    return (
        <div className="flex flex-col items-center justify-center font-light" onClick={() => setPlayerFocused(true)}>
            <AudioPlayer
                header={playlist[currentTrack].name}
                className="custom-audio-player rounded-xl"
                autoPlay={false}
                src={playlist[currentTrack].src}
                showSkipControls
                showFilledVolume={true}
                customVolumeControls={getCustomVolumeControls()}
                onClickNext={handleNextTrack}
                onClickPrevious={handlePreviousTrack}
                onEnded={handleNextTrack}
                onError={() => console.log('Audio load error')}
            />
            <div className="playlist mt-4 p-4 rounded-lg shadow-lg w-full">
                <ul>
                    {playlist.map((track, index) => (
                        <li
                            key={index}
                            ref={(el) => (trackRefs.current[index] = el)}
                            className={`flex justify-between cursor-pointer p-2 ${index === currentTrack ? 'active_track' : 'passive_track'}`}
                            onClick={() => changeTrack(index)}
                        >
                            <div className="flex justify-between w-full">
                                <span className="mr-auto w-10/12 py-1">
                                    {index === currentTrack ? renderActiveTrackMarquee(track) : renderTrackLabel(track)}
                                </span>
                                <button
                                    className="ml-auto text-white px-2 py-1 font-medium rounded-xl hover:text-red-800 hover:bg-white"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDownload(`${track.name} - ${track.author}`, track.src);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faDownload}/>
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default MusicPlayer;

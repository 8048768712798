import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { motion } from "motion/react";
import Logo from "./Logo";
import { HashLink as Link } from 'react-router-hash-link';
import { useLocation } from "react-router-dom";
import { useLanguage } from "../context/LanguageContext";

const navLinks = ["HOME", "RINGTONE", "COLLABORATION", "FAQ"];

function smoothScrollToSection(
    setIsClicking: Dispatch<SetStateAction<boolean>>,
    setActiveHash: Dispatch<SetStateAction<string>>
) {
    return (hash: string, event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        setIsClicking(true);

        const element = document.getElementById(hash);
        if (element) window.scrollTo({top: element.offsetTop, behavior: "smooth"});

        setActiveHash(`#${hash}`);
        setTimeout(() => setIsClicking(false), 300);
    };
}

const Navbar = () => {
    const location = useLocation();
    const [activeHash, setActiveHash] = useState<string>(location.hash);
    const [isClicking, setIsClicking] = useState<boolean>(false);
    const {translations} = useLanguage();

    const handleScroll = () => {
        if (!isClicking) {
            const scrollPosition = window.scrollY;

            const hashes = [
                {hash: navLinks[0], min: 0, max: 299},
                {hash: navLinks[1], min: 300, max: 699},
                {hash: navLinks[2], min: 700, max: 1299},
                {hash: navLinks[3], min: 1400, max: Infinity}
            ];

            const currentHash = hashes.find(h => scrollPosition >= h.min && scrollPosition <= h.max);
            if (currentHash && activeHash !== `#${currentHash.hash}`) setActiveHash(`#${currentHash.hash}`);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [activeHash, isClicking]);

    useEffect(() => {
        setActiveHash(location.hash);
        handleScroll();
    }, [location.hash]);

    const handleClick = smoothScrollToSection(setIsClicking, setActiveHash);
    const isActive = (hash: string): boolean => activeHash === `#${hash}`;

    return (
        <motion.div
            className="navbar flex flex-col items-center w-full shadow"
            initial={{opacity: 0, y: -100}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 1, ease: "easeOut"}}
        >
            <motion.div
                initial={{scale: 0.8, opacity: 0}}
                animate={{scale: 1, opacity: 1}}
                transition={{duration: 1, ease: "backOut"}}
            >
                <Logo/>
            </motion.div>
            <motion.div
                className="flex justify-center items-center gap-8 mt-10"
                initial={{opacity: 0, y: 50}}
                animate={{opacity: 1, y: 0}}
                transition={{delay: 0.5, duration: 1, ease: "easeOut"}}
            >
                {navLinks.map((key) => (
                    <motion.div
                        key={key}
                        initial={{scale: 1}}
                        whileHover={{scale: 1.2, rotate: 2}}
                        whileTap={{scale: 0.95}}
                        transition={{type: "spring", stiffness: 300, damping: 10}}
                    >
                        <Link
                            smooth
                            to={`#${key}`}
                            className={`${isActive(key) ? "link_active" : "link"}`}
                            onClick={(event) => handleClick(key, event)}
                        >
                            <motion.span
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                transition={{delay: 0.3, duration: 0.5}}
                            >
                                {translations[key]}
                            </motion.span>
                        </Link>
                    </motion.div>
                ))}

                {/*<LanguageDropdown/>*/}
            </motion.div>
        </motion.div>
    );
};

export default Navbar;

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useLanguage } from "../context/LanguageContext";
import { Urls } from "../constants/Url";

type FAQType = {
    question: string;
    answer: string;
};

type FAQItemProps = {
    faq: FAQType;
    index: number;
    isActive: boolean;
    onClick: (index: number) => void;
};

const FAQItem: React.FC<FAQItemProps> = ({faq, index, isActive, onClick}) => (
    <div key={index} className="mb-2">
        <div
            className="flex justify-between items-center cursor-pointer py-2 px-3 bg-red-800 text-white rounded-t-lg"
            onClick={() => onClick(index)}>
            <span>{faq.question}</span>
            <FontAwesomeIcon icon={isActive ? faChevronUp : faChevronDown}/>
        </div>
        {isActive && (
            <div className="py-2 px-3 bg-gray-300 rounded-b-lg">
                {faq.answer}
            </div>
        )}
    </div>
);

const FAQ = () => {
    const [faqs, setFaqs] = useState<FAQType[]>([]);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const {language, translations} = useLanguage();

    useEffect(() => {
        const fetchFAQs = async () => {
            try {
                const response = await fetch(`${Urls.BASE}/json/faqs${language === 'TR' ? '_tr' : '_en'}.json`);
                const data = await response.json();
                setFaqs(data);
            } catch (error) {
                console.error('Failed to load FAQs', error);
            }
        };

        fetchFAQs();
    }, [language]);

    const toggleFAQ = (index: number) => setActiveIndex(activeIndex === index ? null : index);

    return (
        <div className="flex justify-center mt-28">
            <a className={"anchor"} id={"FAQ"}/>
            <div className="w-11/12 md:w-1/2">
                <h1 className="text-xl font-bold text-white text-center mb-4">
                    {translations.FAQ}
                </h1>
                {faqs.map((faq, index) => (
                    <FAQItem
                        key={index}
                        faq={faq}
                        index={index}
                        isActive={activeIndex === index}
                        onClick={toggleFAQ}
                    />
                ))}
            </div>
        </div>
    );
};

export default FAQ;
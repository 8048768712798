import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram, faTiktok, faXTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { useLanguage } from "../context/LanguageContext";
import PDFDropdown from "./dropdowns/PDFDropdown";

const email: string = "tricktri3d@gmail.com";
const urls: { [key: string]: string } = {
    youtube: "https://www.youtube.com/channel/UCgOTi6p9zUaoWrobbV0lMqA?sub_confirmation=1",
    instagram: "https://www.instagram.com/tricktri3d",
    facebook: "https://www.facebook.com/tricktri3d",
    tiktok: "https://www.tiktok.com/@tricktri3d",
    twitter: "https://www.twitter.com/tricktri3d"
};

class SocialButton extends React.Component<{ icon: any, label: any, url: any }> {
    render() {
        let {icon, label, url} = this.props;
        return (
            <button
                onMouseDown={(event) => handleSocialClick(event, url)}
                className="bg-red-800 text-white font-medium rounded py-2 px-4 flex items-center space-x-2 w-64 justify-center">
                <FontAwesomeIcon style={{fontSize: '1.5em'}} icon={icon}/>
                <span>{label}</span>
            </button>
        );
    }
}

const getEmail = () => {
    return (
        <a href="mailto:tricktri3d@gmail.com" className="flex justify-center font-extrabold space-x-2">
            <FontAwesomeIcon style={{fontSize: '1.5em'}} icon={faEnvelope}/>
            <span>{email}</span>
        </a>
    );
}

const handleSocialClick = (event: React.MouseEvent<HTMLButtonElement>, url: string) => {
    if (event.button === 0 || event.button === 1) window.open(url, "_blank");
}

const getSocialAccounts = () => {
    return <>
        <SocialButton icon={faYoutube} label="YouTube" url={urls.youtube}/>
        <SocialButton icon={faInstagram} label="Instagram" url={urls.instagram}/>
        <SocialButton icon={faFacebook} label="Facebook" url={urls.facebook}/>
        <SocialButton icon={faTiktok} label="TikTok" url={urls.tiktok}/>
        <SocialButton icon={faXTwitter} label="Twitter" url={urls.twitter}/>
    </>;
}

const SocialMediaLinks = () => {
    const {translations} = useLanguage();
    return (
        <div className="flex flex-col items-center justify-center text-white mt-32">
            <div className="space-y-4">
                {getEmail()}
                {getSocialAccounts()}
            </div>
            <p className="text-center mt-8 w-full px-2 text-sm sm:w-96 sm:text-base sm:px-4 xsm:px-1 xsm:text-xs">
                {translations.DESCRIPTION}
            </p>
            <PDFDropdown/>
        </div>
    );
}

export default SocialMediaLinks;
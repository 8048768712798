import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { LanguageProvider } from "./context/LanguageContext";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <LanguageProvider>
        <App/>
    </LanguageProvider>
);

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from "./components/Footer";
import Home from "./views/Home";
import NotFound from "./views/NotFound";
import Navbar from "./components/Navbar";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={
                    <>
                        <Navbar/>
                        <Home/>
                        <Footer/>
                    </>
                }/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </Router>
    );
}

export default App;
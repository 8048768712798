import SocialMediaLinks from "../components/SocialMediaLinks";
import React from "react";
import Ringtone from "./Ringtone";
import FAQ from "./FAQ";
import Collaboration from "./Collaboration";

const Home = () => (
    <div className="flex flex-col min-h-screen">
        <div className="flex-grow">
            <a className={"anchor"} id={"HOME"}/>
            <SocialMediaLinks/>
            <Ringtone/>
            <Collaboration/>
            {/*<Video/>*/}
            <FAQ/>
        </div>
    </div>
);

export default Home;
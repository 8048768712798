import React, { useEffect, useRef, useState } from "react";
import { useLanguage } from "../context/LanguageContext";
import { Urls } from "../constants/Url";

type Company = {
    name: string;
    logo: string;
    url: string;
};

const playlistUrl = `${Urls.BASE}/json/collaborations.json`;
const Collaboration = () => {
    const {translations} = useLanguage();
    const [collaborations, setCollaborations] = useState<Company[]>([]);
    const companyRefs = useRef<(HTMLAnchorElement | null)[]>([]);

    useEffect(() => {
        fetch(playlistUrl)
            .then(response => response.json())
            .then(data => setCollaborations(data))
            .catch(error => console.error('Error fetching collaborations:', error));
    }, []);

    if (collaborations.length === 0) {
        return <div className="flex flex-col items-center justify-center font-light text-white">Loading...</div>;
    }

    return (
        <div className="flex flex-col items-center mt-28 text-white">
            <a className="anchor" id="COLLABORATION"/>
            <div className="w-full overflow-hidden">
                <h2 className="text-2xl font-bold mb-6 text-center">{translations.COLLABORATION}</h2>
                <div className="flex md:justify-center justify-start overflow-x-auto space-x-2 px-4 scroll-smooth">
                    {collaborations.map((company, index) => (
                        <a
                            key={company.name}
                            href={company.url}
                            target="_blank"
                            rel="noreferrer"
                            className="no-underline flex-shrink-0"
                            ref={(el) => (companyRefs.current[index] = el)}
                        >
                            <div
                                className="flex items-center min-w-max border-4 rounded-xl border-black hover:border-red-800 transition">
                                <img src={company.logo} alt={`${company.name} logo`}
                                     className="h-20 w-auto block object-cover"/>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Collaboration;
import { useLanguage } from "../context/LanguageContext";

const Footer = () => {
    const {translations} = useLanguage();
    let year = new Date().getFullYear();
    const github = "https://github.com/kalayciburak";

    return (
        <footer className="footer text-white m-4 text-xs shadow">
            <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between md:flex-wrap">
                <span className="block sm:flex-grow md:flex-grow-0">{translations.DEVELOPER}
                    <a className={"font-bold"} href={`${github}`} target="_blank"
                       rel="noopener noreferrer"> Burak Kalaycı</a>
                </span>
                <span className="block mt-2 sm:mt-0">© {year} <span className={"font-extrabold text-red-500"}>
                    <a href={"/"}>Tricktri™</a>
                </span> {translations.RIGHTS}</span>
            </div>
        </footer>
    );
}

export default Footer;